import React, { useEffect } from "react";
import { Field } from "../../common/types";
import { Marker } from "react-map-gl";
import checkicon from "../../images/check_icon.png";
import warningicon from "../../images/warningicon.png";
import defaulticon from "../../images/defaulticon.png";

interface Props {
  field: Field;
}

function FieldMarker({ field }: Props) {
  const [icon, setIcon] = React.useState(checkicon);

  useEffect(() => {
    console.log(field.indexes.ndvi);
    if (field.indexes.ndvi > 0.3) {
      setIcon(checkicon);
      console.log("Good");
    } else if (field.indexes.ndvi < 0.3) {
      console.log("Warning");
      setIcon(warningicon);
    } else {
      setIcon(defaulticon);
    }
  }, [field]);

  return (
    <div>
      {" "}
      <Marker
        key={field.id}
        longitude={field.centerPoint[1]}
        latitude={field.centerPoint[0]}
        scale={1}
      >
        <div className="flex flex-row bg-white text-black font-custom1 p-2 text-sm rounded-md gap-1.5 items-center">
          <p className="text-xs">
            {field.name} &bull; {field.crop}
          </p>
          <img src={icon} alt="checkicon" className="rounded-full w-4 h-4" />
        </div>
      </Marker>
    </div>
  );
}

export default FieldMarker;
