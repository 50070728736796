import { Field, GeoJsonField, Feature, Properties } from "./types";
import * as turf from "@turf/turf";
import bbox from "@turf/bbox";

export function populateFields(data: GeoJsonField) {
  const tempCoordinates: number[][] = [];
  const tempFields: Field[] = [];
  data.features.forEach(function (feature) {
    const properties = feature.properties;
    const featureGeometry = feature.geometry;
    const featureCoordinates = featureGeometry.coordinates[0];

    let field: Field = {
      name: properties.name,
      area: turf.area(featureGeometry) / 10000,
      crop: properties.crop,
      id: properties.id,
      utcUpdate: 0,
      centerPoint: [0, 0],
      indexes: {
        ndvi: 0,
        ndwi: 0,
      },
      ndviLinkLayer: "",
      ndwiLinkLayer: "",
    };

    const [minLng, minLat, maxLng, maxLat] = bbox(featureGeometry);
    field.centerPoint = [(minLat + maxLat) / 2, (minLng + maxLng) / 2];

    featureCoordinates.map((coordinate) => {
      tempCoordinates.push(coordinate);
    });
    tempFields.push(field);
  });
  return { tempFields, tempCoordinates };
}

export function findCenter(coords: number[][]) {
  let latSum = 0;
  let lngSum = 0;
  let count = 0;

  coords.forEach((coord) => {
    latSum += coord[1];
    lngSum += coord[0];
    count += 1;
  });
  return [latSum / count, lngSum / count];
}

export function getStatusFromNdvi(ndvi: number): string[] {
  if (ndvi >= 0.8) {
    return [
      "C'è una presenza molto elevata di vegetazione o la vegetazione è lussureggiante. Le piante sono in perfetta salute e stanno crescendo in modo ottimale.",
      "Il campo è in ottima forma! Continuare con lo stesso trattamento.",
    ];
  } else if (ndvi >= 0.6 && ndvi < 0.8) {
    return [
      "C'è una presenza elevata di vegetazione o la vegetazione è molto sana. Le piante sono in ottima salute e stanno crescendo molto bene.",
      "Il campo è in buona forma! Continuare con lo stesso trattamento.",
    ];
  } else if (ndvi >= 0.4 && ndvi < 0.6) {
    return [
      "C'è una buona presenza di vegetazione o la vegetazione è sana.",
      "Continuare a fornire alle piante la giusta quantità di acqua e nutrienti, monitorare eventuali problemi di malattie e parassiti e assicurarsi che le piante ricevano una buona esposizione solare.",
    ];
  } else if (ndvi >= 0.2 && ndvi < 0.4) {
    return [
      "C'è una presenza moderata di vegetazione o la vegetazione ha un lieve stress.",
      "Continuare a fornire alle piante la giusta quantità di acqua e nutrienti, monitorare eventuali problemi di malattie e parassiti e considerare l'applicazione di un fertilizzante.",
    ];
  } else if (ndvi >= 0 && ndvi < 0.2) {
    return [
      "C'è poca vegetazione o la vegetazione ha un moderato stress.",
      "Fornire alle piante la giusta quantità di acqua e nutrienti e applicare un fertilizzante per aiutare le piante a crescere in modo sano.",
    ];
  } else if (ndvi >= -0.2 && ndvi < 0) {
    return [
      "C'è pochissima vegetazione o la vegetazione è molto stressata.",
      "Verificare che le piante ricevano la giusta quantità di acqua e nutrienti, controllare eventuali problemi di malattie e parassiti e considerare l'applicazione di un fertilizzante.",
    ];
  } else if (ndvi >= -0.4 && ndvi < -0.2) {
    return [
      "C'è una presenza moderatamente bassa di vegetazione o la vegetazione è moderatamente stressata.",
      "Controllare le piante e fornire loro la giusta quantità di acqua e nutrienti, nonché applicare un fertilizzante per aiutare le piante a crescere in modo sano.",
    ];
  } else if (ndvi >= -0.6 && ndvi < -0.4) {
    return [
      "C'è poca vegetazione o la vegetazione è stressata.",
      "Aumentare la quantità di acqua e di nutrienti che le piante ricevono, controllare eventuali problemi di parassiti e malattie, e se necessario, applicare un fertilizzante.",
    ];
  } else if (ndvi >= -0.8 && ndvi < -0.6) {
    return [
      "C'è pochissima vegetazione o la vegetazione è molto stressata.",
      "Aumentare la quantità di acqua e di nutrienti che le piante ricevono e, se necessario, applicare un fertilizzante per aiutare le piante a recuperare.",
    ];
  } else if (ndvi > -1 && ndvi < -0.8) {
    return [
      "Non c'è copertura vegetale o le piante sono stressate.",
      "Controllare le piante per identificare eventuali problemi come malattie o parassiti e aumentare la quantità di acqua e nutrienti che ricevono.",
    ];
  }
  return ["Nessun dato disponibile"];
}

interface NDMIRange {
  description: string;
  advice: string;
}

export function getStatusFromNdwi(ndwi: number): NDMIRange {
  if (ndwi < -0.2) {
    return {
      description: "Terreno secco con poca o nessuna umidità.",
      advice:
        "Irrigare il terreno regolarmente per assicurarsi che mantenga abbastanza umidità per la crescita delle piante.",
    };
  } else if (ndwi < -0.1) {
    return {
      description: "Il terreno è secco con una limitata presenza di acqua.",
      advice:
        "Aumentare la frequenza dell'irrigazione o applicare più acqua per assicurarsi che ci sia abbastanza umidità per la crescita delle piante.",
    };
  } else if (ndwi < 0) {
    return {
      description: "Il terreno ha una bassa presenza di acqua.",
      advice:
        "Aumentare la frequenza dell'irrigazione o applicare più acqua per assicurarsi che ci sia abbastanza umidità per la crescita delle piante.",
    };
  } else if (ndwi < 0.1) {
    return {
      description: "Il terreno ha una moderata presenza di acqua.",
      advice:
        "Continuare ad irrigare il terreno come necessario per assicurarsi che ci sia abbastanza umidità per la crescita delle piante.",
    };
  } else if (ndwi < 0.2) {
    return {
      description: "Il terreno ha una buona presenza di acqua.",
      advice: "Continuare ad irrigare il terreno con regolarità.",
    };
  } else if (ndwi < 0.3) {
    return {
      description: "Il terreno ha una elevata presenza di acqua.",
      advice:
        "Monitorare il terreno per assicurarsi che non diventi eccessivamente saturo di acqua.",
    };
  } else if (ndwi < 0.4) {
    return {
      description: "Il terreno è molto umido.",
      advice:
        "Ridurre la frequenza dell'irrigazione e regolare il numero di irrigazioni.",
    };
  } else if (ndwi < 0.5) {
    return {
      description: "Il terreno è eccessivamente umido.",
      advice: "Ridurre leggermente la frequenza dell'irrigazione.",
    };
  } else if (ndwi < 0.6) {
    return {
      description: "Il terreno è saturo di acqua.",
      advice: "Ridurre la frequenza di irrigazione.",
    };
  } else {
    return {
      description: "Il terreno è allagato.",
      advice:
        "Lasciare asciugare il terreno prima di innaffiare di nuovo. Valutare di migliorare il drenaggio del terreno per prevenire il ristagno idrico in futuro.",
    };
  }
}
