import type { FillLayer, LineLayer, RasterLayer } from "react-map-gl";

export const dataLayer: FillLayer = {
  id: "data",
  type: "fill",
  paint: {
    "fill-color": [
      "match",
      ["get", "crop"],
      "Riso",
      "#FC0FC0",
      "Mais da seme",
      "#e6f598",
      "Erba Medica",
      "#ffff00",
      "#ffffff",
    ],
    "fill-opacity": 0.3,
  },
};

export const lineLayer: LineLayer = {
  id: "line",
  type: "line",
  layout: {
    "line-join": "round",
    "line-cap": "round",
  },
  paint: {
    "line-color": "#3288bd",
    "line-width": 2,
  },
};
export const rasterNDVILayer: RasterLayer = {
  id: "rasterNDVI",
  type: "raster",
  minzoom: 0,
  maxzoom: 20,
  paint: {
    "raster-opacity": 1,
  },
};
