import React from "react";

interface Props {
  indexValue: number;
}

function StatusCard({ indexValue }: Props) {
  let status = "";
  let statusColor = "";

  if (indexValue > 0.5) {
    status = "Ottimale";
    statusColor = "text-green-500";
  } else if (indexValue >= 0.3 && indexValue <= 0.5) {
    status = "Buono";
    statusColor = "text-green-500";
  } else {
    status = "In allarme";
    statusColor = "text-[#FF0600]";
  }

  return (
    <div className="grid bg-white py-3 px-5 rounded-xl justify-items-center grow drop-shadow">
      <h1 className="text-xl text-[#57B3FF]">Stato</h1>
      <h1 className={`text-3xl ${statusColor}`}>{status}</h1>
    </div>
  );
}

export default StatusCard;
