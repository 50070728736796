import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCC2CtR0IzcXCN8trkcw1IkIy1LiW8CeI4",
  authDomain: "wopstation-9526d.firebaseapp.com",
  projectId: "wopstation-9526d",
  storageBucket: "wopstation-9526d.appspot.com",
  messagingSenderId: "740820272075",
  appId: "1:740820272075:web:ef7135b1768208b7b3ec4e",
  measurementId: "G-0RM3R15QPJ",
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
