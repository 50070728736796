import { Field } from "../common/types";

const API_KEY = "dcda7be9d8f6b70e412ed550d81ac077";
const BASE_URL =
  "https://api.agromonitoring.com/agro/1.0/image/search?start=1641989908";
const USER_FIELD_DATA =
  "https://gist.githubusercontent.com/JimboPower/95a79e1a6a8cefd9fd622b2e74448d20/raw/cf4681ce10d4c98a295a53d8a41b20ca58c8c49b/TestFieldsGigi.json";

export const getFieldsData = async (userData: string) => {
  return fetch(userData).then((res) => res.json());
};

function fetchAgromonitoringData(field: Field): Promise<any> {
  const currentTime = Date.now() / 1000;
  // const currentTime = 1642508308;
  const url = `${BASE_URL}&end=${currentTime}&polyid=${field.id}&appid=${API_KEY}&paletteid=1`;
  return fetch(url).then((res) => res.json());
}

async function fetchIndicesData(url: string): Promise<number> {
  url = url.replace(/^http:\/\//i, "https://");
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`Failed to fetch data from ${url}.`);
  }
  const res = await response.json();
  return res.median;
}

export async function getAgromonitoringData(field: Field): Promise<Field> {
  try {
    const snapshots = await fetchAgromonitoringData(field);

    let hasRequiredData = false;
    let lastSnapshotData = null;

    do {
      lastSnapshotData = snapshots[snapshots.length - 1];

      if (lastSnapshotData.stats.ndvi === undefined) {
        snapshots.pop();
      } else if (lastSnapshotData.cl === 100) {
        snapshots.pop();
      } else {
        hasRequiredData = true;
      }
    } while (!hasRequiredData);

    field.indexes.ndvi = await fetchIndicesData(lastSnapshotData.stats.ndvi);
    field.indexes.ndwi = await fetchIndicesData(lastSnapshotData.stats.ndwi);
    field.ndviLinkLayer =
      lastSnapshotData.tile.ndvi.replace(/^http:\/\//i, "https://") +
      "&paletteid=4";
    field.ndwiLinkLayer =
      lastSnapshotData.tile.ndwi.replace(/^http:\/\//i, "https://") +
      "&paletteid=1";

    field.utcUpdate = lastSnapshotData.dt;

    return field;
  } catch (error) {
    throw new Error(`Failed to get agromonitoring data: ${error}`);
  }
}
