import MyMap from "../components/Map/MyMap";
import { useState, useEffect } from "react";
import Dashboard from "../components/Dashboard/Dashboard";
import {
  getFieldsData,
  getAgromonitoringData,
} from "../services/agromonitoringService";
import { Field, GeoJsonField, Feature, Properties } from "../common/types";
import { populateFields, findCenter } from "../common/util";

function Home({
  userData,
  userName,
  location,
  companyName,
}: {
  userData: string;
  userName: string;
  location: string;
  companyName: string;
}) {
  const [allData, setAllData] = useState<any>(null);
  const [isLoadingFieldsIndices, setIsLoadingFieldsIndices] = useState(false);
  const [fields, setFields] = useState<Field[]>([]);
  const [coordinates, setCoordinates] = useState<number[][]>([]);
  const [zoomCenter, setZoomCenter] = useState([0, 0]);
  const [selectedFieldIDFromMap, setselectedFieldIDFromMap] =
    useState<string>("");
  const [layerLink, setLayerLink] = useState("");
  const [cropFieldsDict, setCropFieldsDict] = useState<Record<string, Field[]>>(
    {}
  );

  const emptyField = {
    name: "Default",
    area: 0,
    utcUpdate: 0,
    crop: "Default",
    id: "Default",
    centerPoint: [0, 0],
    indexes: { ndvi: 0, ndwi: 0 },
    ndviLinkLayer: "",
    ndwiLinkLayer: "",
  };

  const [selectedFieldFromMap, setSelectedFieldFromMap] =
    useState<Field>(emptyField);
  const [selectedFieldFromDashboard, setSelectedFieldFromDashboard] =
    useState<Field>(emptyField);

  function populateCropFieldDict(fields: Field[]) {
    let dictionary = {} as Record<string, Field[]>;
    for (let i = 0; i < fields.length; i++) {
      let obj = fields[i];
      if (dictionary[obj.crop] === undefined) {
        dictionary[obj.crop] = [obj];
      } else {
        dictionary[obj.crop].push(obj);
      }
    }
    return dictionary;
  }

  useEffect(() => {}, []);

  useEffect(() => {
    const selectedField = fields.find(
      (field) => field.id === selectedFieldIDFromMap
    );
    setSelectedFieldFromMap(selectedField as Field);
    setselectedFieldIDFromMap("");
  }, [selectedFieldIDFromMap]);

  useEffect(() => {
    var numFields = 0;
    const fetchUserDataFields = async () => {
      const data = await getFieldsData(userData).then((data) => {
        //Update data to map
        setAllData(data);

        //Create a temporary list of type Fields from geojson
        const { tempCoordinates, tempFields } = populateFields(data);
        setCoordinates(tempCoordinates);
        setFields(tempFields);

        numFields = tempFields.length;
        var counter = 0;

        tempFields.map((field) => {
          getAgromonitoringData(field).then(() => {
            counter++;
            if (counter === numFields - 1) {
              setIsLoadingFieldsIndices(true);
            }
          });
        });
      });
    };

    fetchUserDataFields();
  }, []);

  useEffect(() => {
    //Sort fields by name
    fields.sort((a, b) => a.name.localeCompare(b.name));
    setCropFieldsDict(populateCropFieldDict(fields));
  }, [fields]);

  useEffect(() => {
    if (coordinates.length != 0) {
      setZoomCenter(findCenter(coordinates));
    }
  }, [coordinates]);

  return (
    <div className="grid grid-cols-8 h-screen">
      <div className="col-span-8 md:col-span-4 lg:col-span-5 h-full">
        <MyMap
          allData={allData}
          fields={fields}
          zoomCenter={zoomCenter}
          fieldSelectedFromDashboard={selectedFieldFromDashboard}
          onFieldClicked={(id) => {
            setselectedFieldIDFromMap(id as string);
          }}
          layerLink={layerLink}
          isLoadingIndices={isLoadingFieldsIndices}
        />
      </div>

      <Dashboard
        fields={fields}
        cropFieldsDict={cropFieldsDict}
        selectedFieldFromMap={selectedFieldFromMap}
        onFieldClicked={(field) => {
          setSelectedFieldFromDashboard(field);
        }}
        updateLayerLink={(type) => {
          setLayerLink(type);
        }}
        userName={userName}
        location={location}
        companyName={companyName}
      />
    </div>
  );
}

export default Home;
