import React from "react";
import { iconUrlFromCode } from "../../../../services/weatherService";

function ForecastDaily({ items }) {
  return (
    <div className="bg-white drop-shadow rounded-xl py-3 px-3">
      <div className="flex flex-row items-center justify-between text-black px-3">
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center justify-center"
          >
            {index === 0 ? (
              <p className="font-light text-lg text-[#7795ff]">Oggi</p>
            ) : (
              <p className="font-light text-lg">
                {item.title.charAt(0).toUpperCase() + item.title.slice(1)}
              </p>
            )}
            <img
              src={iconUrlFromCode(item.icon)}
              className="w-12 my-1 drop-shadow"
              alt=""
            />
            <p className="font-medium ">
              <span className="text-[#7795ff]">{item.temp_min.toFixed()}°</span>{" "}
              -{" "}
              <span className="text-[#ff8e25]">{`${item.temp_max.toFixed()}°`}</span>
            </p>
            <p className="text-[#7795ff] text-sm">{item.rain} mm</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ForecastDaily;
