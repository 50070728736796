import { useEffect, useState } from "react";
import DashboardNavbar from "../DashboardNavbar";
import WeatherCards from "../Weather/Weather";
import { Field } from "../../../common/types";
import { getStatusFromNdvi, getStatusFromNdwi } from "../../../common/util";
import IndexStatusCard from "./IndexStatusCard";
import StatusCard from "./StatusCard";

type Props = {
  onButtonBackClick: () => void;
  updateLayerLink: (type: string) => void;
};

function DetailField({
  field,
  onButtonBackClick,
  updateLayerLink,
}: {
  field: Field;
  onButtonBackClick: Props[`onButtonBackClick`];
  updateLayerLink: Props[`updateLayerLink`];
}) {
  const [cetDate, setCetDate] = useState("");
  const [layerLink, setLayerLink] = useState("null");

  const [isNDVIButtonClicked, setIsNDVIButtonClicked] = useState(false);
  const [isNDWIButtonClicked, setIsNDWIButtonClicked] = useState(false);

  useEffect(() => {
    const date = new Date(field.utcUpdate * 1000); // Convert Unix timestamp to JavaScript Date object
    const options: Intl.DateTimeFormatOptions = {
      timeZone: "CET",
      weekday: "long",
      month: "long",
      day: "numeric",
    };
    const cetDate = date.toLocaleString("it-IT", options); // Format date and time string in CET
    setCetDate(cetDate);

    resetLayerLink();
  }, [field]);

  function resetLayerLink() {
    setLayerLink("null");
    setIsNDVIButtonClicked(false);
    setIsNDWIButtonClicked(false);
  }

  useEffect(() => {
    console.log("Uptated");
    updateLayerLink(layerLink);
  }, [layerLink]);

  return (
    <div>
      <DashboardNavbar
        handleClick={() => {
          onButtonBackClick();
          updateLayerLink("null");
        }}
        title={field.name}
      />
      <div className="font-custom1 py-3 rounded-xl flex flex-col gap-3 px-7">
        <div className="flex justify-center pb-3 gap-3">
          <div className="bg-white py-3 px-5 rounded-xl drop-shadow-xs grid justify-items-center grow drop-shadow">
            <h1 className="text-lg text-[#57B3FF]">Coltura</h1>
            <h1 className="text-xl">{field.crop}</h1>
          </div>
          <div className="bg-white py-3 px-5 rounded-xl drop-shadow-xs grid justify-items-center grow drop-shadow">
            <h1 className="text-lg text-[#57B3FF]">Ettari</h1>
            <h1 className="text-xl">{field.area.toFixed(1)}</h1>
          </div>
        </div>
        <StatusCard indexValue={field.indexes.ndvi} />

        <h1 className="text-2xl font-bold mt-3">Stato del terreno</h1>
        <IndexStatusCard
          title="Stato vegetazione"
          description={getStatusFromNdvi(field.indexes.ndvi)[0]}
          advice={getStatusFromNdvi(field.indexes.ndvi)[1]}
          textButton="test"
          handleClick={() => {
            if (isNDVIButtonClicked) {
              resetLayerLink();
            } else {
              setIsNDVIButtonClicked(true);
              setLayerLink("ndvi");
            }
          }}
        />
        <IndexStatusCard
          title="Stato d'irrigazione"
          description={getStatusFromNdwi(field.indexes.ndwi).description}
          advice={getStatusFromNdwi(field.indexes.ndwi).advice}
          textButton="test"
          handleClick={() => {
            if (isNDWIButtonClicked) {
              resetLayerLink();
            } else {
              setIsNDWIButtonClicked(true);
              setLayerLink("ndwi");
            }
          }}
        />

        <div className="">
          <h1 className="text-gray-300">Ultimo aggiornamento: {cetDate}</h1>
          <h1 className="">
            Dato visibile solo agli sviluppatori: NDVI ={" "}
            {field.indexes.ndvi.toFixed(2)} NDWI ={" "}
            {field.indexes.ndwi.toFixed(2)}
          </h1>
        </div>
      </div>
      <div className="px-7">
        <WeatherCards />
      </div>
      <div className="h-10"></div>
    </div>
  );
}

export default DetailField;
