import { useEffect, useState } from "react";
import TemperatureAndUmidityDetail from "./WeatherCards/TemperatureAndUmidityDetails";
import getFormattedWeatherData from "../../../services/weatherService";
import CurrentWeather from "./WeatherCards/CurrentWeather";
import ForecastDaily from "./WeatherCards/ForecastDaily";
import ForecastHourly from "./WeatherCards/ForecastHourly";
import RainForecast from "./WeatherCards/RainForecast";

function Weather() {
  const [query] = useState({ q: "basiglio" });
  const [units] = useState("metric");
  const [lang] = useState("it");
  const [weather, setWeather] = useState(null);

  useEffect(() => {
    const fetchWeather = async () => {
      await getFormattedWeatherData({
        ...query,
        units, 
        lang,
      }).then((data) => {
        setWeather(data);
      });
    };
    fetchWeather();
  }, [query, units, lang]);

  return (
    <div>
      {weather && (
        <div className="flex flex-col gap-4 font-custom1 mt-5">
          <div>
            <h1 className="text-2xl mb-1">Meteo di Oggi</h1>
            <CurrentWeather weather={weather} />
          </div>
          <TemperatureAndUmidityDetail weather={weather} />
          <ForecastHourly weather={weather} items={weather.hourly} />
          <div className="mt-2">
            <h1 className="text-xl mb-1">
              Previsioni meteo dei prossimi giorni
            </h1>
            <ForecastDaily weather={weather} items={weather.daily} />
          </div>
          <RainForecast weather={weather} />
        </div>
      )}
    </div>
  );
}

export default Weather;
