import * as React from "react";
import { useState, useEffect, useCallback, useRef } from "react";
import homeimg from "../../images/home.png";
import { dataLayer, lineLayer, rasterNDVILayer } from "./map-style";
import { Field } from "../../common/types";
import Map, { Source, Layer, MapLayerMouseEvent, MapRef } from "react-map-gl";
import FieldMarker from "./Marker";
import LoadingFieldsData from "./LoadingFieldsData";
import LegendHeatMap from "./LegendHeatMap";

const MAPBOX_TOKEN =
  "pk.eyJ1IjoiZWRvYXJkb2RjbCIsImEiOiJjbGEzdzVueXgwODdrM3BybnplaTd0OXZhIn0.oQGXciCY5owbARJ67R2lFQ"; // Set your mapbox token here

interface Props {
  zoomCenter: [number, number];
  allData: GeoJSON.FeatureCollection;
  fields: Field[];
  onFieldClicked: (id: string) => void;
  fieldSelectedFromDashboard: Field | null;
  layerLink: string;
  isLoadingIndices: boolean;
}

export default function MyMap({
  zoomCenter,
  allData,
  fields,
  onFieldClicked,
  fieldSelectedFromDashboard,
  layerLink,
  isLoadingIndices,
}: Props) {
  const [selectedField, setSelectedField] = useState<Field | null>(null);
  const mapRef = useRef<null | MapRef>(null);
  const fieldsRef = useRef(fields);
  const selectedFieldRef = useRef(selectedField);
  const [isShowingLayer, setIsShowingLayer] = useState(false);
  const [viewState, setViewState] = useState({
    longitude: 0,
    latitude: 0,
    zoom: 16,
  });

  const [URL, setURL] = useState([
    "https://api.agromonitoring.com/tile/1.0/{z}/{x}/{y}",
  ]);

  function resetLayer() {
    if (mapRef.current?.getMap().getSource("rasterNDVI")) {
      mapRef.current?.getMap().removeLayer("rasterNDVI");
      mapRef.current?.getMap().removeSource("rasterNDVI");
    }
  }

  useEffect(() => {
    setTimeout(() => {
      resetLayer();
      mapRef.current?.getMap().addLayer({
        id: "rasterNDVI",
        source: {
          type: "raster",
          tiles: URL,
        },
        type: "raster",
        minzoom: 0,
        maxzoom: 20,
        paint: {
          "raster-opacity": 1,
        },
      });
    }, 1000);
  }, [URL]);

  useEffect(() => {
    console.log("Status: ", layerLink);
    console.log("Field: ", selectedField?.ndviLinkLayer);

    if (layerLink === "ndvi") {
      setURL([selectedField?.ndviLinkLayer ?? ""]);
      setIsShowingLayer(true);
    }

    if (layerLink === "ndwi") {
      setURL([selectedField?.ndwiLinkLayer ?? ""]);
      setIsShowingLayer(true);
    }
    if (layerLink === "null") {
      resetLayer();
      setURL(["https://api.agromonitoring.com/tile/1.0/{z}/{x}/{y}"]);
      setIsShowingLayer(false);
    }
  }, [layerLink]);

  useEffect(() => {
    fieldsRef.current = fields;
  }, [fields]);

  useEffect(() => {
    selectedFieldRef.current = selectedField;

    if (selectedField) {
      mapRef.current?.flyTo({
        center: [selectedField.centerPoint[1], selectedField.centerPoint[0]],
        duration: 1700,
        zoom: 17,
      });
    }
  }, [selectedField]);

  useEffect(() => {
    if (fieldSelectedFromDashboard) {
      setSelectedField(fieldSelectedFromDashboard);
    }
  }, [fieldSelectedFromDashboard]);

  useEffect(() => {
    setViewState({
      longitude: zoomCenter[1],
      latitude: zoomCenter[0],
      zoom: 16,
    });
  }, [zoomCenter]);

  function getSelectedFieldFromMap(id: string): Field {
    const field = fieldsRef.current.find((field) => field.id === id);
    if (!field) {
      throw new Error("Field not found");
    }
    return field;
  }

  const onClick = useCallback((event: MapLayerMouseEvent) => {
    const { features } = event;
    const clickedFeature = features && features[0];

    if (clickedFeature) {
      setSelectedField(
        getSelectedFieldFromMap(clickedFeature.properties?.id as string)
      );
      onFieldClicked(clickedFeature.properties?.id as string);
    }
  }, []);

  return (
    <div className="relative h-[50vh] md:h-screen">
      <Map
        ref={mapRef}
        {...viewState}
        onMove={(evt) => setViewState(evt.viewState)}
        mapStyle="mapbox://styles/mapbox/satellite-v9"
        mapboxAccessToken={MAPBOX_TOKEN}
        interactiveLayerIds={["data"]}
        onClick={onClick}
        cursor={"auto"}
        style={{
          width: "100%",
        }}
      >
        <Source type="geojson" data={allData}>
          <Layer {...dataLayer} />
          <Layer {...lineLayer} />
        </Source>
        {isLoadingIndices &&
          fields.map((field) => <FieldMarker field={field} />)}
      </Map>

      <div className="absolute top-8 md:bottom-auto md:top-8 inset-x-0 md:left-auto md:right-5 flex justify-between px-2">
        <button
          className="bg-white rounded-full h-14 w-14  place-content-center"
          onClick={() => {
            mapRef.current?.flyTo({
              center: [zoomCenter[1], zoomCenter[0]],
              duration: 2000,
              zoom: 16,
            });
          }}
        >
          <img
            src={homeimg}
            alt="home"
            className=" rounded-full w-12 h-9 mx-auto"
          />
        </button>
      </div>

      <div className="absolute inset-0 top-12 bottom-auto flex items-center justify-center">
        {!isLoadingIndices && <LoadingFieldsData />}
      </div>

      <div className="absolute inset-0 bottom-10 top-auto flex items-center justify-center sm:invisible md:visible">
        {/* {isShowingLayer && <LegendHeatMap />} */}
      </div>
    </div>
  );
}
