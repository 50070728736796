import { render } from "react-dom";
import { StrictMode } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import "mapbox-gl/dist/mapbox-gl.css";
import ReactDOM from "react-dom/client";
import Login from "./pages/Login";
import { AuthContextProvider } from "./context/AuthContext";
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";
import { Navigate } from "react-router-dom";
import World from "./pages/World";

const USER_FIELD_DATA_GIGI =
  "https://gist.githubusercontent.com/JimboPower/95a79e1a6a8cefd9fd622b2e74448d20/raw/cf4681ce10d4c98a295a53d8a41b20ca58c8c49b/TestFieldsGigi.json";

const USER_FIELD_DATA_LORENZO =
  "https://gist.githubusercontent.com/JimboPower/c7d682003ef0b0159a0f8c29ad54c906/raw/b654d09422d8e68a9e6e3dcebd7d348641033255/Campi-Luca-Airoli.json";

const App = () => {
  const { currentUser } = useContext(AuthContext);

  const RequireAuth = ({ children }) => {
    return currentUser ? children : <Navigate to="/login" />;
  };

  return (
    <StrictMode>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route path="login" element={<Login />} />
            <Route path="world" element={<World />} />
            <Route
              path="lorenzo"
              element={
                <Home
                  userName="Lorenzo Airoli"
                  location="Basiglio"
                  companyName="Azienda Airoli"
                  userData={USER_FIELD_DATA_LORENZO}
                />
              }
            />
            <Route
              index
              element={
                <RequireAuth>
                  <Home
                    userName="Gigi Penati"
                    location="Basiglio"
                    companyName="Azienda Penati"
                    userData={USER_FIELD_DATA_GIGI}
                  />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <StrictMode>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </StrictMode>
);
