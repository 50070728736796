import Main from "./Main/Main";
import ListOfAllFields from "./ListOfAllFields/ListOfAllFields";
import { useEffect, useRef, useState } from "react";
import DetailField from "./DetailField/DetailField";
import { Field } from "../../common/types";

type Props = {
  onFieldClicked: (field: Field) => void;
  updateLayerLink: (type: string) => void;
};

function Dashboard({
  fields,
  cropFieldsDict,
  selectedFieldFromMap,
  onFieldClicked,
  updateLayerLink,
  userName,
  location,
  companyName,
}: {
  fields: Field[];
  cropFieldsDict: Record<string, Field[]>;
  selectedFieldFromMap: Field;
  onFieldClicked: Props[`onFieldClicked`];
  updateLayerLink: Props[`updateLayerLink`];
  userName: string;
  location: string;
  companyName: string;
}) {
  const dashboardContainer = useRef<HTMLDivElement>(null);
  const [selectedView, setSelectedView] = useState<
    "main" | "listOfAllFields" | "detailField"
  >("main");

  const emptyField = {
    name: "Default",
    area: 0,
    utcUpdate: 0,
    crop: "Default",
    id: "Default",
    centerPoint: [0, 0],
    indexes: { ndvi: 0, ndwi: 0 },
    ndviLinkLayer: "",
    ndwiLinkLayer: "",
  };

  const [selectedField, setSelectedField] = useState<Field>(emptyField);

  function scrollToTop() {
    if (!dashboardContainer.current) return;
    dashboardContainer.current.scrollTop = 0;
  }

  useEffect(() => {
    scrollToTop();
  }, [selectedView]);

  useEffect(() => {
    if (!selectedFieldFromMap) return;
    if (selectedFieldFromMap.name !== "Default") {
      setSelectedField(selectedFieldFromMap);
      setSelectedView("detailField");
    }
    scrollToTop();
  }, [selectedFieldFromMap]);

  return (
    <div
      ref={dashboardContainer}
      className="col-span-8 md:col-span-4 lg:col-span-3 overflow-y-scroll relative"
    >
      {selectedView === "main" && (
        <Main
          fields={fields}
          cropFieldsDict={cropFieldsDict}
          onButtonClick={() => {
            setSelectedView("listOfAllFields");
          }}
          userName={userName}
          location={location}
          companyName={companyName}
        />
      )}
      {selectedView === "listOfAllFields" && (
        <div className="flex flex-col">
          <ListOfAllFields
            cropFieldsDict={cropFieldsDict}
            fields={fields}
            onButtonFieldClick={(field) => {
              setSelectedField(field);
              setSelectedView("detailField");
              onFieldClicked(field);
            }}
            onButtonBackClick={() => setSelectedView("main")}
          />
        </div>
      )}

      {selectedView === "detailField" && (
        <div className="flex flex-col">
          <DetailField
            field={selectedField}
            onButtonBackClick={() => {
              setSelectedView("listOfAllFields");
            }}
            updateLayerLink={(type) => {
              updateLayerLink(type);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default Dashboard;
