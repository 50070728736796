import RightArrow from "../../shared/RightArrow";

type Props = {
  onButtonClick: () => void;
};

function AllFieldsButton({
  numFields,
  onButtonClick,
}: {
  numFields: number;
  onButtonClick: Props[`onButtonClick`];
}) {
  return (
    <div>
      <button className="w-full" onClick={onButtonClick}>
        <div className="flex justify-between bg-white py-3 px-4 pr-7 rounded-xl drop-shadow font-custom1 hover:scale-102 transition duration-300">
          <h1 className="text-xl">Tutti i tuoi campi</h1>
          <div className="flex items-center gap-4">
            <div className="flex gap-1 text-lg">
              <h1 className="text-[#C7C7C7]">
                {numFields} {numFields > 1 ? "Campi" : "Campo"}{" "}
              </h1>
            </div>
            <RightArrow />
          </div>
        </div>
      </button>
    </div>
  );
}

export default AllFieldsButton;
