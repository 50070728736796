import React from "react";
import { CSpinner } from "@coreui/react";

function LoadingFieldsData() {
  return (
    <div className="bg-white drop-shadow py-2 px-3 font-custom1 rounded-xl">
      <h1>In caricamento...</h1>
    </div>
  );
}

export default LoadingFieldsData;
