import React from "react";

function TemperatureAndUmidityDetail({ weather: { humidity, today } }) {
  return (
    <div className="flex flex-row font-custom1 rounded-xl gap-4">
      <div className="bg-white text-[#7795ff] py-3 px-5 rounded-xl drop-shadow grid justify-items-center grow">
        <h1 className="text-md">Temp. minima</h1>
        <h1 className="text-4xl">{today.temp.min.toFixed()}&deg;C</h1>
      </div>
      <div className="bg-white text-[#ff8e25] py-3 px-5 rounded-xl drop-shadow grid justify-items-center grow">
        <h1 className="text-md">Temp. massima</h1>
        <h1 className="text-3xl">{today.temp.max.toFixed()}&deg;C</h1>
      </div>
      <div className="bg-white text-black py-3 px-5 rounded-xl drop-shadow grid justify-items-center grow">
        <h1 className="text-md">Umidità</h1>
        <h1 className="text-4xl">{humidity.toFixed()}%</h1>
      </div>
    </div>
  );
}

export default TemperatureAndUmidityDetail;
