import React, { useState } from "react";

interface Props {
  title: string;
  description: string;
  advice: string;
  textButton: string;
  handleClick: () => void;
}

function IndexStatusCard({ title, description, advice, handleClick }: Props) {
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  return (
    <div className="bg-white py-3 px-5 rounded-xl drop-shadow-xs grid grow drop-shadow">
      <h1 className="text-2xl text-[#57B3FF] text-center mt-2">{title}</h1>
      <p className="text-lg text-left mt-2">{description}</p>
      <p className="text-lg mt-2 text-left">
        <span className="text-[#57B3FF] mt-2">Consiglio:</span> {advice}
      </p>
      <button
        className="bg-[#57B3FF] text-white rounded-md px-4 py-2 mt-5 mb-2 w-60 justify-self-center"
        onClick={() => {
          setIsButtonClicked(!isButtonClicked);
          handleClick();
        }}
      >
        {isButtonClicked ? "Rimuovi dalla mappa" : "Visualizza sulla mappa"}
      </button>
    </div>
  );
}

export default IndexStatusCard;
