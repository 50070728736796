import { Field } from "../../../common/types";
import DashboardNavbar from "../DashboardNavbar";
import RightArrow from "../../shared/RightArrow";
import warningicon from "../../../images/warningicon.png";
import checkicon from "../../../images/check_icon.png";
import defaulticon from "../../../images/defaulticon.png";
import { useState } from "react";

type Props = {
  onButtonBackClick: () => void;
  onButtonFieldClick: (field: Field) => void;
};

function ListOfAllFields({
  fields,
  cropFieldsDict,
  onButtonBackClick,
  onButtonFieldClick,
}: {
  fields: Field[];
  onButtonBackClick: Props[`onButtonBackClick`];
  cropFieldsDict: Record<string, Field[]>;
  onButtonFieldClick: Props[`onButtonFieldClick`];
}) {
  const [icon, setIcon] = useState(defaulticon);

  function getIcon(ndviValue: number): string {
    if (ndviValue > 0.3) {
      return checkicon;
    } else if (ndviValue == 0) {
      return defaulticon;
    } else {
      return warningicon;
    }
  }

  return (
    <div className="font-custom1">
      <DashboardNavbar
        handleClick={onButtonBackClick}
        title="Tutti i tuoi campi"
      />

      <div className="px-7">
        {Object.keys(cropFieldsDict)
          .sort((a, b) => cropFieldsDict[b].length - cropFieldsDict[a].length)
          .map((key) => (
            <div className="flex flex-col gap-1 mb-3">
              <h1 className="text-2xl font-bold mt-5">{key}</h1>
              {cropFieldsDict[key].map((field) => (
                <button
                  className="w-full"
                  onClick={() => onButtonFieldClick(field)}
                >
                  <div className="flex justify-between bg-white rounded-xl drop-shadow font-custom1 py-3 px-4 pr-7 mt-2 hover:scale-102 transition duration-300">
                    <h1 className="text-lg">{field.name}</h1>
                    <div className="flex items-center gap-2">
                      <div className="flex">
                        <img
                          src={getIcon(field.indexes.ndvi)}
                          alt="checkicon"
                          className="rounded-full w-5 h-5"
                        />
                      </div>
                      <div className="flex gap-3 items-center">
                        <p>&bull;</p>
                        <p className="whitespace-nowrap">
                          {field.area.toFixed(1)} Ettari
                        </p>
                      </div>
                      <RightArrow />
                    </div>
                  </div>
                </button>
              ))}
            </div>
          ))}
        <div className="h-10"></div>
      </div>
    </div>
  );
}

export default ListOfAllFields;
