import React, { FC } from "react";
import LeftArrow from "../shared/LeftArrow";

interface Props {
  handleClick: () => void;
  title: string;
}
const DashboardNavbar: FC<Props> = ({ handleClick, title }) => {
  return (
    <div className="flex relative w-full gap-5 my-2 py-4 border-b border-gray-100 px-5">
      <button
        type="button"
        className="flex absolute inset-y-0 gap-1 items-center font-custom1 text-lg text-[#57B3FF]"
        onClick={handleClick}
      >
        <LeftArrow />
        <p className="flex-grow text-sm sm:text-md md:text-sm xl:text-lg">
          Indietro
        </p>
      </button>
      <div className="font-custom1 text-xl sm:text-3xl xl:text-3xl md:text-2xl text-center flex-grow">
        {title}
      </div>
    </div>
  );
};

export default DashboardNavbar;
