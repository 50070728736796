function DetailFarm({
  totalArea,
  companyName,
  location,
}: {
  totalArea: string;
  companyName: string;
  location: string;
}) {
  function getCurrentFullDate() {
    let date = new Date();
    let options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      month: "long",
      day: "numeric",
    };
    let fullDate = date.toLocaleDateString("it-IT", options);
    return fullDate.charAt(0).toUpperCase() + fullDate.slice(1);
  }

  return (
    <div className="font-custom1 bg-white py-3 px-4 rounded-xl drop-shadow">
      <div className="flex items-end gap-2 mb-2">
        <div className="flex items-center gap-2">
          <h1 className="md:text-xl lg:text-3xl sm:text-xs text-[#57B3FF]">
            {companyName}
          </h1>
          <h3 className="text-lg">&bull;</h3>
        </div>
        <h3 className="text-lg text-[#C7C7C7]">{location}</h3>
      </div>

      <div className="flex justify-center pb-3 gap-5">
        <div className="bg-white py-3 px-5 rounded-xl drop-shadow-3xl grid justify-items-center grow">
          <h1 className="text-lg text-[#57B3FF]">Data</h1>
          <h1 className="text-xl">{getCurrentFullDate()}</h1>
        </div>

        <div className="bg-white py-3 px-5 rounded-xl drop-shadow-3xl grid justify-items-center grow">
          <h1 className="text-lg text-[#57B3FF]">Ettari totali</h1>
          <h1 className="text-xl">{totalArea}</h1>
        </div>
      </div>
    </div>
  );
}

export default DetailFarm;
