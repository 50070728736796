import avatarimg from "../../../images/userimage.png";

const AccountNav = ({ userName }: { userName: string }) => {
  return (
    <div className="md:sticky bottom-0 inset-x-0 right-0 h-20vh w-full max-w-full px-10 border-t border-gray-100 z-10 bg-white">
      <div className="flex justify-between items-center py-3 font-custom1 text-[#7795ff]">
        <div className="flex items-center gap-3">
          <img src={avatarimg} alt="sun" className="rounded-full w-10 h-10" />
          <span className="text-xl">{userName}</span>
        </div>
        {/* <button type="button">Esci</button> */}
      </div>
    </div>
  );
};

export default AccountNav;
