import React, { useEffect } from "react";

function RainForecast({ weather: { rainyDays } }) {
  const [rainSummary, setRainSummary] = React.useState("");

  function getDayName(unixTimestamp) {
    const daysOfWeek = [
      "domenica",
      "lunedì",
      "martedì",
      "mercoledì",
      "giovedì",
      "venerdì",
      "sabato",
    ];
    const dateObj = new Date(unixTimestamp); // converte il timestamp Unix in millisecondi
    const dayOfWeek = dateObj.getDay(); // restituisce il numero del giorno della settimana (0-6)
    return daysOfWeek[dayOfWeek]; // restituisce il nome del giorno della settimana in italiano
  }

  useEffect(() => {
    const rainyDaysArray = Object.entries(rainyDays).map(([unixTime, rain]) => [
      new Date(unixTime * 1000),
      rain,
    ]);

    rainyDaysArray.sort(([date1], [date2]) => date1 - date2);

    var totalRain = 0;
    let rainyDayCount = 0;

    let rainSummary = "";
    rainyDaysArray.forEach(([date, rainfall]) => {
      if (rainfall > 0) {
        rainyDayCount += 1;
        totalRain += rainfall;
        rainSummary += `Pioverà ${getDayName(date)} con circa ${rainfall}mm`;
      }
    });
    if (rainyDayCount === 0) {
      rainSummary += "Nei prossimi giorni non è prevista pioggia.\n";
    }

    setRainSummary(rainSummary);
  }, [rainyDays]);

  return (
    <div className="bg-white py-3 px-5 rounded-xl grid drop-shadow grow">
      <h1 className="text-lg text-[#57B3FF] justify-self-center">
        mm di pioggia previsti
      </h1>
      <div className="text-left">
        <h1 className="text-xl">È prevista pioggia:</h1>
        <h1 className="text-lg">Oggi con circa 1,59 mm</h1>
        <h1 className="text-lg">Domani con circa 1,02 mm</h1>
        <h1 className="text-lg">Mercoledí con circa 9,24 mm</h1>
      </div>
    </div>
  );
}

export default RainForecast;
