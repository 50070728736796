import React from "react";
import { iconUrlFromCode } from "../../../../services/weatherService";

function CurrentWeather({ weather: { icon, temp, description } }) {
  return (
    <div className="flex items-center grow rounded-xl drop-shadow bg-white py-2 px-5">
      <img
        src={iconUrlFromCode(icon)}
        alt="sun"
        className="h-15 w-15 drop-shadow"
      />
      <h1 className="text-3xl text-black ml-5">
        {" "}
        {description.charAt(0).toUpperCase() + description.slice(1)} &bull;{" "}
        {temp.toFixed()}&deg;C
      </h1>
    </div>
  );
}

export default CurrentWeather;
