import { useEffect, useState } from "react";
import AllFieldsButton from "./AllFieldsButton";
import DetailFarm from "./DetailFarm";
import { Field } from "../../../common/types";
import Weather from "../Weather/Weather";
import AccountNav from "./AccountNav";

type Props = {
  onButtonClick: () => void;
};

function Main({
  fields,
  cropFieldsDict,
  onButtonClick,
  userName,
  location,
  companyName,
}: {
  fields: Field[];
  cropFieldsDict: Record<string, Field[]>;
  onButtonClick: Props[`onButtonClick`];
  userName: string;
  location: string;
  companyName: string;
}) {
  var [totalArea, setTotalArea] = useState(0);

  useEffect(() => {
    getTotalFieldsArea();
  }, [fields]);

  function getTotalFieldsArea() {
    totalArea = 0;
    fields.forEach((field) => {
      totalArea += field.area;
      setTotalArea(totalArea);
    });
  }

  return (
    <>
      <div className="p-7 md:min-h-[88vh] font-custom1">
        <h1 className="text-center text-5xl mb-4 text-[#57B3FF] hidden md:block">
          WOP
        </h1>
        <div className="flex flex-col justify-in-between gap-3 h-full">
          <DetailFarm
            companyName={companyName}
            location={location}
            totalArea={totalArea.toFixed(2)}
          />
          <AllFieldsButton
            onButtonClick={onButtonClick}
            numFields={fields.length}
          />
          <Weather />
        </div>
        <p className="text-center mt-5 font-custom2 text-gray-500">
          Wop Station - Version 0.3.1
        </p>
      </div>
      <AccountNav userName={userName} />
    </>
  );
}

export default Main;
